import {set} from 'date-fns';
import {ImageSize} from './image-size.enum';

export class Clinic {
	readonly uuid: string;
	readonly name: string;
	readonly type: ClinicType;
	readonly primaryColorHex: string;
	readonly directorName: string;
	readonly visibleOnLanding: boolean;
	readonly visibleOnMobile: boolean;
	readonly address?: string;
	readonly alternativeClinicName?: string;
	readonly images?: Array<{imageUrl: string, size: ImageSize}>;
	readonly latitude?: number;
	readonly longitude?: number;
	readonly phoneNumber?: string;
	readonly faxNumber?: string;
	readonly supervisorDoctorEmail?: string;
	readonly supervisorDoctorUuid?: string;
	readonly supervisorDoctorName?: string;
	readonly website?: string;
	readonly priorityForOnlineAppointments?: number; // starting from 0
	readonly orderNo: number;
	readonly generalChannelId?: string;
	readonly workingHours?: ClinicWorkingHours;

	constructor(params?: {
		uuid: string,
		name: string,
		type: ClinicType,
		primaryColorHex: string,
		directorName: string,
		visibleOnLanding: boolean,
		visibleOnMobile: boolean,
		address?: string,
		alternativeClinicName?: string,
		images?: Array<{imageUrl: string, size: ImageSize}>,
		latitude?: number,
		longitude?: number,
		phoneNumber?: string,
		faxNumber?: string,
		supervisorDoctorEmail?: string,
		supervisorDoctorUuid?: string,
		supervisorDoctorName?: string,
		website?: string,
		isPreferredForOnlineAppointments?: boolean;
		workingHours?: ClinicWorkingHours
	}) {
		if (params) {
			Object.assign(this, params);
		}
	}

	getOpeningTimeForDate(date: Date): Date {
		return set(date, {hours: this.getOpeningTimeHour(), minutes: this.getOpeningTimeMinute(), seconds: 0, milliseconds: 0});
	}

	getClosingTimeForDate(date: Date): Date {
		return set(date, {hours: this.getClosingTimeHour(), minutes: this.getClosingTimeMinute(), seconds: 0, milliseconds: 0});
	}

	getOpeningTimeHour(): number {
		return Number(this.workingHours.opensAt.split(':')[0]);
	}

	getOpeningTimeMinute(): number {
		return Number(this.workingHours.opensAt.split(':')[1]);
	}

	getClosingTimeHour(): number {
		return Number(this.workingHours.closesAt.split(':')[0]);
	}

	getClosingTimeMinute(): number {
		return Number(this.workingHours.closesAt.split(':')[1]);
	}

	hasDoctors(): boolean {
		return this.type !== ClinicType.Salon;
	}

	isSalon(): boolean {
		return this.type === ClinicType.Salon;
	}

	static deserialize(response: any): Clinic {
		const params = {
			uuid: response.uuid,
			name: response.name,
			type: ClinicType[response.type],
			primaryColorHex: response.primaryColorHex,
			directorName: response.directorName,
			visibleOnLanding: response.visibleOnLanding,
			visibleOnMobile: response.visibleOnMobile,
			address: response.address,
			alternativeClinicName: response.alternativeClinicName,
			images: response.images,
			latitude: response.latitude,
			longitude: response.longitude,
			phoneNumber: response.phoneNumber,
			faxNumber: response.phoneNumber,
			supervisorDoctorEmail: response.supervisorDoctorEmail,
			supervisorDoctorUuid: response.supervisorDoctorUuid,
			supervisorDoctorName: response.supervisorDoctorName,
			website: response.website,
			priorityForOnlineAppointments: response.priorityForOnlineAppointments,
			orderNo: response.orderNo,
			generalChannelId: response.generalChannelId,
			workingHours: {
				atZone: response.workingHours.atZone,
				closesAt: response.workingHours.closesAt,
				opensAt: response.workingHours.opensAt
			}
		};
		return new Clinic(params);
	}

	static update(clinic: Clinic, update: { visibleOnLanding?: boolean, visibleOnMobile?: boolean }): Clinic {
		return Object.assign(new Clinic(), clinic, update);
	}
}

interface ClinicWorkingHours {
	atZone: string;
	closesAt: string; // format "17:00:00"
	opensAt: string; // format "09:00:00"
}

export enum ClinicType {
	Regular = 'Regular',
	Salon = 'Salon'
}
